import React, { Component } from "react";
import HeaderTitle from "./HeaderTitle";

export class Team extends Component {
  state = {
    team: [
      {
        name: 'Christian Bonola',
        puesto: 'Dirección General',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
        facebook: '',
        twitter: '',
        linkein: ''
      },
      {
        name: 'Esteban Moreno',
        puesto: 'Títular Litigios Laborales',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
        facebook: '',
        twitter: '',
        linkein: ''
      },
      {
        name: 'Heidi Maldonado',
        puesto: 'Logistica y Operaciones',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
        facebook: '',
        twitter: '',
        linkein: ''
      },
    ]
  }
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <HeaderTitle title="Equipo" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          <div id="row">
            {this.state.team.map((t, idx) => (
              <div className="col-xs-12 col-md-4" key={idx}>
                <div className="panel panel-default panel-card">
                  <div className="panel-body mb-5">
                    <div className="row">
                      <h3 className="mt-5 title-card-team">{t.name}</h3>
                      <h2 className="subtitle-card-team">
                        {t.puesto}
                      </h2>
                    </div>
                    <div className="row p-4">
                      <p className="text-card">{t.description}</p>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <a href="!#" target="_blank" rel="noreferrer">
                          <img src="/img/team/facebook.png" alt="facebook" />
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="!#" target="_blank" rel="noreferrer">
                          <img src="/img/team/twitter.png" alt="facebook" />
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="!#" target="_blank" rel="noreferrer">
                          <img src="/img/team/linkedin.png" alt="facebook" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
