import React, { useState } from 'react';
import GoogleMaps from './GoogleMaps';

const styleArray = [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#fcfcfc",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#fcfcfc",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#dddddd",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#dddddd",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#dddddd",
        },
      ],
    },
  ];

const socialMedia  = [
    { icon: '/img/header/facebook.png', actionUrl: 'https://www.facebook.com/BRACfirmalegal', alt: 'Facebook' },
    { icon: '/img/header/twitter.png', actionUrl: 'https://twitter.com/BRACfirmalegal', alt: 'Twitter' },
    { icon: '/img/header/instagram.png', actionUrl: 'https://www.linkedin.com/in/christian-bonola-roman-5b9273125/', alt: 'Instragram' },
]

const state = [
    { scroll: 'page-top', label: 'Inicio'},
    { scroll: 'features', label: 'Nosotros'},
    { scroll: 'about', label: 'Servicios'},
    { scroll: 'team', label: 'Equipo'},
    { scroll: 'contact', label: 'Contacto'},
]

const Footer = ({ scrollFunction }) => {
    const [isOpen, setOpen ] = useState(false);
    // 9.3906797,-99.284041,
    const coordenades = { lat: 19.36, lng: -99.18 }

    return ( 
        <div id="footer">
            <div className="">
                <div className="row m-0">
                    <div className="col-md-4 p-0">
                        <GoogleMaps 
                            isOpen={isOpen}
                            setOpen={setOpen}
                            coordenades={coordenades}
                            containerElement={<div style={{ height: `600px` }} />}
                            mapElement={<div style={{ height: `100%` }} />} 
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCDSDCRenHmN5N4h_NGRVAlSQbaw41N4PY" 
                            loadingElement={<div style={{ height: `100%` }} />}
                            styles={styleArray}
                        />
                    </div>
                    <div className="col-md-8">
                        <div className="row container mt-5 ml-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <h3>Contacto</h3>
                                    <p className="mt-5">
                                        Blvd. Benito Juárez, Edif. 45, Planta Baja,
                                    </p>
                                    <p className="mt-5">
                                        Desp 20, Colonia Las Palmas, C.P. 62050, Cuernavaca, Morelos
                                    </p>
                                    <p className="mt-5">
                                        T. 777 242 57 42 <br/> 777 318 59 71
                                    </p>
                                </div>
                                <div className="col-md-offset-2 col-md-6">
                                    <h3>Navegación</h3>
                                    {
                                        state.map(val => (
                                            <p className="mt-5" key={val.scroll}>
                                                <a href="!#" onClick={(e) => scrollFunction(e, val.scroll)} className="page-scroll">
                                                    { val.label }
                                                </a>
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3>Redes Sociales</h3>
                                        </div>
                                        {
                                            socialMedia.map(i =>(
                                                <div className="col-md-2" key={i.icon}>
                                                    <a href={i.actionUrl} target="_blank" rel="noreferrer">
                                                        <img src={i.icon} alt={i.alt} />
                                                    </a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;