import React from 'react'

const HeaderTitle = ({ title, subtitle= '' }) => {
  return ( <div className="row">
    <div className="row mb-5">
      <div className="col-md-10 col-md-offset-1 section-title">
        <h2 className="h1-title">{title}</h2>
      </div>
      <div className="col-md-offset-2 col-md-8">
        <p className="p-subtitle">
          {subtitle} 
        </p>
      </div>
    </div>
  </div>);
}
 
export default HeaderTitle;