import React, { Component } from "react";
import HeaderTitle from './HeaderTitle';

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact" className="text-center">
          <div className="container">
            <HeaderTitle title="Contacto" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
            <div className="row" />
            <div className="row">
              <div className="col-md-4">
                <img src="/img/contact.png" className="w-100" alt="Contacto" />
              </div>
              <div className="col-md-8">
                <form className="p-5">
                  <div className="row">
                    <input type="text" className="form-control" placeholder="Nombre Completo" />
                  </div>
                  <div className="row">
                    <input type="email" className="form-control mt-5" placeholder="Correo Electronico" />
                  </div>
                  <div className="row">
                    <textarea rows="100" className="form-control mt-5" placeholder="Mensaje" style={{height: '150px'}}>

                    </textarea>
                  </div>
                  <div className="row mt-5">
                    <input type="submit" value="Enviar" className="btn btn-submit btn-block"/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
