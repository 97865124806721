import React from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { InfoBox }from "react-google-maps/lib/components/addons/InfoBox";
import marker from '../data/marker.svg';


const GoogleMaps = withScriptjs(withGoogleMap(props => (
    <GoogleMap defaultZoom={11} defaultCenter={props.coordenades} defaultOptions={{ styles: props.styles}}>
        <Marker position={props.coordenades}
                onClick={() => props.setOpen(!props.isOpen)}
                icon={{
                    url: marker,
                }}>
            {props.isOpen && <InfoBox onCloseClick={() => props.setOpen(!props.isOpen)} options={{ closeBoxURL: ``, enableEventPropagation: true }}>
                <div style={{ backgroundColor: `#3498db`, opacity: 0.75, padding: `12px` }}>
                        <div style={{ fontSize: `16px`, color: '#fff' }}>
                            ¡Visitanos!
                        </div>
                    </div>
                </InfoBox>}
            </Marker>
        </GoogleMap>
)))
 
export default GoogleMaps;