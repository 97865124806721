import React from 'react';
import animateScrollTo from 'animated-scroll-to';
import Navigation from './components/navigation';
import Header from './components/header';
import About from './components/about';
import Services from './components/services';
import Team from './components/Team';
import Contact from './components/contact';
import Footer from './components/Footer';

const App = () => {
  const scrollFunction = (e, item) => {
    e.preventDefault()
    animateScrollTo(document.querySelector(`#${item}`), { speed : 1000 });
  }
    return (
      <div>
        <Navigation scrollFunction={scrollFunction} />
        <Header scrollFunction={scrollFunction} />
        <About />
        <Services />
        <Team />
        <Contact/>
        <Footer scrollFunction={scrollFunction} />
      </div>
    )
}

export default App
