import React from "react";

const Header = ({ scrollFunction }) => {
  const information  = [
    { icon: '/img/header/location.png', actionUrl: 'https://www.google.com/maps/search/?api=1&query=18.907495,-99.231630', alt: 'Ubicanos' },
    { icon: '/img/header/phone.png', actionUrl: 'tel:+527772425742', alt: 'Llamanos' },
    { icon: '/img/header/email.png', actionUrl: 'mailto:contacto@bracfirmalegal.com ', alt: 'Correo' },
  ]

  const socialMedia  = [
    { icon: '/img/header/facebook.png', actionUrl: 'https://www.facebook.com/BRACfirmalegal', alt: 'Facebook' },
    { icon: '/img/header/twitter.png', actionUrl: 'https://twitter.com/BRACfirmalegal', alt: 'Twitter' },
    { icon: '/img/header/instagram.png', actionUrl: 'https://www.linkedin.com/in/christian-bonola-roman-5b9273125/', alt: 'Instragram' },
  ]

  const data = {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum."
  }
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-1 intro-text">
                  <div className="row">
                    {
                      information.map(i => (
                        <div className="col-md-12 mt-5" key={i.icon}>
                          <a href={i.actionUrl} target="_blank" rel="noreferrer">
                            <img src={i.icon} alt={i.alt} />
                          </a>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="col-md-10 intro-text">
                  <div className="row">
                    <div className="col-md-12">
                      <h1>
                      {data && data.title}
                      <span></span>
                      </h1>
                    </div>
                    <div className="col-md-12">
                      <p className="mt-5">
                        {data && data.paragraph}
                      </p>
                    </div>
                    <div className="col-md-4 col-md-offset-4">
                      <a href="!#" onClick={e => scrollFunction(e, 'contact')} className="btn btn-custom btn-block btn-lg page-scroll">
                        Contactar
                      </a>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-1 intro-text">
                  <div className="row">
                  {
                      socialMedia.map(i => (
                        <div className="col-md-12 mt-5" key={i.icon}>
                          <a href={i.actionUrl} target="_blank" rel="noreferrer">
                            <img src={i.icon} alt={i.alt} />
                          </a>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
}

export default Header;
