import React, { Component } from 'react'
import HeaderTitle from './HeaderTitle';

export class about extends Component {
  state = {
    cards: [
      { icon: '/img/nosotros/resultados.png', alt:'resultados', title: 'Grandes resultados', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' },
      { icon: '/img/nosotros/experiencia.png', alt:'experiencia', title: 'Experiencia', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' },
      { icon: '/img/nosotros/dedicacion.png', alt:'dedicacion', title: 'Dedicación', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' },
    ]
  }
  render() {
    return (
      <div id="features" className="text-center">
        <div className="container">
          <HeaderTitle title="Acerca de Nosotros" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "/>
          <div className="row mb-5" />
          <div className="row mt-5">
            {
              this.state.cards.map(c => (
                <div className="col-xs-12 col-md-4" key={c.icon}>
                  <div className="panel panel-default panel-card">
                    <div className="panel-body mb-5">
                      <img src={c.icon} className="mt-5" alt={c.alt} />
                      <h3 className="mt-5 title-card">{c.title}</h3>
                      <p className="text-card">{c.description}</p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default about
