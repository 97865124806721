import React from "react";

const Navigation = ({ scrollFunction }) => {
  const state = [
    { scroll: 'page-top', label: 'Inicio'},
    { scroll: 'features', label: 'Nosotros'},
    { scroll: 'about', label: 'Servicios'},
    { scroll: 'team', label: 'Equipo'},
    { scroll: 'contact', label: 'Contacto'},
  ]

    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll p-0 ml-5" href="!#" onClick={(e) => scrollFunction(e, 'page-top')}>
              <img src="/img/brac-logo.jpeg" alt="BRAC Firma Legal" />
            </a>{" "}
          </div>

          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              {
                state.map((val, idx) => (
                  <li key={idx}>
                    <a href="!#" onClick={(e) => scrollFunction(e, val.scroll)} className="page-scroll">
                      { val.label }
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </nav>
    );
}

export default Navigation;
