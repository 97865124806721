import React, { Component } from "react";
import HeaderTitle from "./HeaderTitle";

export class Services extends Component {
  state = {
    services: [
      { icon: '/img/services/derecho_corporativo.png',  title: 'Derecho Corporativo' },
      { icon: '/img/services/derecho_laboral.png',  title: 'Derecho Laboral y Seguridad Social' },
      { icon: '/img/services/derecho_familiar.png',  title: 'Derecho Familiar' },
      { icon: '/img/services/derecho_civil_y_merc.png',  title: 'Derecho Civil y Mercantil' },
      { icon: '/img/services/recuperacion_cartera.png',  title: 'Recuperación Cartera Vencida' },
    ]
  }
  render() {
    return (
        <div id="about" className="text-center">
        <div className="container">
          <HeaderTitle title="Servicios" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          <div className="row"/>
          <div className="row">
            {
              this.state.services.map(s => (
                <div className="col-md-4 col-xs-12 mt-5" key={s.icon}>
                  <img src={s.icon} className="w-80" alt ={s.title} />
                  <div className="centered">
                    <label className="">{s.title}</label>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Services;
